import { useChatbotStore, useChatStateStore } from "../zustand/store";
import styles from '../style/styles/Buttons.module.css';
import { useEffect } from "react";
import { useSendMessage } from "../services/queries/chatbotQueries";

export const GuideButtonGroup = () => {
    const { chatbot } = useChatbotStore();
    const { setIsChatbotPending } = useChatStateStore();
    const { sendMessage } = useSendMessage();
    
    return (
        <div 
            className={styles.GuideButtonGroup__wrapper}
        >
            {
                chatbot?.examples?.map((example, idx) => (
                    <div 
                        key={idx}
                        className={styles.GuideButtonGroup__button}
                        onClick={() => {
                            sendMessage(example, false);
                            setIsChatbotPending(true);
                        }}
                    >
                        <p className="h3">{example}</p>
                    </div>
                ))
            }
        </div>
    )
}