import React, { useEffect, useState } from 'react';
import styles from '../style/styles/Form.module.css';
import { useChatStateStore, useFormStore, useMessagesStore } from '../zustand/store';
import { useFormSubmitted } from '../services/queries/chatbotQueries';

export const CollectionForm = () => {
    const { setIsFormSubmitted } = useChatStateStore();
    const { setMessages } = useMessagesStore();
    const { mutate: formSubmitted } = useFormSubmitted();
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        agreements: {
            all: false,
            checkPrivacyPolicy: false,
            checkMarketing: false
        }
    });
    const [allValid, setAllValid] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [validList, setValidList] = useState({
        name: false,
        phone: false,
        email: false
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'phone') {
            if (value.length > 11) return;
            const formattedPhone = value.replace(/[^0-9]/g, '');
            if (formattedPhone.length > 8 && formattedPhone.startsWith('010')) {
                setValidList(prev => ({
                    ...prev,
                    phone: true
                }));
            } else {
                setValidList(prev => ({
                    ...prev,
                    phone: false
                }));
            }
            setFormData(prev => ({
                ...prev,
                [name]: formattedPhone
            }));
        } else if (name === 'email') {
            if (!/^[a-zA-Z0-9]+([._-][a-zA-Z0-9]+)?@[^\s@]+\.[^\s@]+$/.test(value)) {
                setValidList(prev => ({
                    ...prev,
                    email: false
                }));
            } else {
                setValidList(prev => ({
                    ...prev,
                    email: true
                }));
            }
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        
        if (name === 'all') {
            setFormData(prev => ({
                ...prev,
                agreements: {
                    all: checked,
                    checkPrivacyPolicy: checked,
                    checkMarketing: checked
                }
            }));
        } else {
            const newAgreements = {
                ...formData.agreements,
                [name]: checked
            };
            
            // Update 'all' checkbox based on other checkboxes
            newAgreements.all = newAgreements.checkPrivacyPolicy && newAgreements.checkMarketing;
            
            setFormData(prev => ({
                ...prev,
                agreements: newAgreements
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        formSubmitted({
            formData: formData,
        });
        setFormData(formData);
        setMessages([{message: formData, type: 'form', isChatbot: false}]);
        setIsSubmitted(true);
        setIsFormSubmitted('submitted');
        const targetWindow = window.parent;
        console.log('targetWindow', targetWindow);
        const formSubmittedState = {
            formSubmittedState: true,
        }
        targetWindow.postMessage(formSubmittedState, "*");
    };  

    useEffect(() => {
        if (
            formData.name.length > 0 
            && formData.phone.length > 0 
            && formData.email.length > 0
            && formData.agreements.checkPrivacyPolicy
        ) {
            setAllValid(true);
        } else {
            setAllValid(false);
        }
    }, [formData])

    return (
        <div className={styles.CollectionForm__container}>
            <div className={styles.CollectionForm__wrapper}>
                <form onSubmit={handleSubmit}>
                    <div className={styles.CollectionForm__group}>
                        <label className={`${styles.CollectionForm__label} ${styles.CollectionForm__required}`}>이름</label>
                        <input
                            type="text"
                            name="name"
                            className={styles.CollectionForm__input}
                            placeholder="이름을 입력해 주세요"
                            value={formData.name}
                            onChange={handleInputChange}
                            disabled={isSubmitted}
                            autoComplete="off"
                        />
                    </div>

                    <div className={styles.CollectionForm__group}>
                        <label className={`${styles.CollectionForm__label} ${styles.CollectionForm__required}`}>번호(-제외)</label>
                        <input
                            type="tel"
                            name="phone"
                            className={(validList.phone || formData.phone.length === 0) ? styles.CollectionForm__input : styles.CollectionForm__input__warning}
                            placeholder="01012341234"
                            value={formData.phone}
                            onChange={handleInputChange}
                            disabled={isSubmitted}
                            autoComplete="off"
                        />
                        {
                            (!validList.phone && formData.phone.length > 0) &&
                            <>
                                <div className='spacing-6' />
                                <div className={styles.CollectionForm__warning}>
                                    <img src='/img/units/cancel.png' alt='cancel' width={16} height={16} />
                                    <p className='h9 fc-warning'>010으로 시작하는 9자리 이상 숫자를 입력해주세요</p>
                                </div>
                            </>
                        }
                    </div>

                    <div className={styles.CollectionForm__group}>
                        <label className={`${styles.CollectionForm__label} ${styles.CollectionForm__required}`}>이메일</label>
                        <input
                            type="email"
                            name="email"
                            className={(validList.email || formData.email.length === 0) ? styles.CollectionForm__input : styles.CollectionForm__input__warning}
                            placeholder="example@example.com"
                            value={formData.email}
                            onChange={handleInputChange}
                            disabled={isSubmitted}
                            autoComplete="off"
                        />
                        {
                            (!validList.email && formData.email.length > 0) &&
                            <>
                                <div className='spacing-6' />
                                <div className={styles.CollectionForm__warning}>
                                    <img src='/img/units/cancel.png' alt='cancel' width={16} height={16} />
                                    <p className='h9 fc-warning'>올바른 형식의 이메일 주소를 입력해 주세요</p>
                                </div>
                            </>
                        }
                    </div>

                    <div className={styles.CollectionForm__checkboxGroup}>
                        <label className={styles.CollectionForm__checkboxLabel}>
                            <input
                                type="checkbox"
                                name="all"
                                checked={formData.agreements.all}
                                onChange={handleCheckboxChange}
                                disabled={isSubmitted}
                            />
                            <span>약관에 모두 동의합니다.</span>
                        </label>
                        <label className={styles.CollectionForm__checkboxLabel}>
                            <input
                                type="checkbox"
                                name="checkPrivacyPolicy"
                                checked={formData.agreements.checkPrivacyPolicy}
                                onChange={handleCheckboxChange}
                                disabled={isSubmitted}
                            />
                            <div>상담을 위한{` `}
                                <span 
                                    className={styles.CollectionForm__link}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open('https://waddlelab.notion.site/1741f2d71bda80b49b97c0f082874a0d?pvs=4', '_blank', 'noreferrer,noopener');
                                    }}
                                >개인정보 수집과 이용</span>{` `}동의 (필수)
                            </div>
                        </label>
                        <label className={styles.CollectionForm__checkboxLabel}>
                            <input
                                type="checkbox"
                                name="checkMarketing"
                                checked={formData.agreements.checkMarketing}
                                onChange={handleCheckboxChange}
                                disabled={isSubmitted}
                            />
                            <div>혜택 안내를 위한 {` `}
                                <span 
                                    className={styles.CollectionForm__link}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open('https://waddlelab.notion.site/1741f2d71bda805183a3e7066b2f2268?pvs=4', '_blank', 'noreferrer,noopener');
                                    }}
                                >마케팅 활용</span>{` `}동의 (선택)
                            </div>
                        </label>
                    </div>
                    {
                        !isSubmitted &&
                        <button 
                            type="submit" 
                            className={allValid && !isSubmitted ? styles.CollectionForm__button : styles.CollectionForm__button__disabled}
                            disabled={isSubmitted || !allValid}
                        >
                            최저가 견적 안내 받기
                        </button>
                    }
                </form>
            </div>
        </div>
    );
};

export const CompletedForm = (props) => {
    const { formData } = props;

    return (
        <div className={styles.CollectionForm__container}>
            <div className={styles.CollectionForm__wrapper}>
                <form>
                    <div className={styles.CollectionForm__group}>
                        <label className={`${styles.CollectionForm__label} ${styles.CollectionForm__required}`}>이름</label>
                        <input
                            type="text"
                            name="name"
                            className={styles.CollectionForm__input}
                            value={formData.name}
                            disabled={true}
                            autoComplete="off"
                        />
                    </div>

                    <div className={styles.CollectionForm__group}>
                        <label className={`${styles.CollectionForm__label} ${styles.CollectionForm__required}`}>번호(-제외)</label>
                        <input
                            type="tel"
                            name="phone"
                            className={styles.CollectionForm__input}
                            value={formData.phone}
                            disabled={true}
                            autoComplete="off"
                        />
                    </div>

                    <div className={styles.CollectionForm__group}>
                        <label className={`${styles.CollectionForm__label} ${styles.CollectionForm__required}`}>이메일</label>
                        <input
                            type="email"
                            name="email"
                            className={styles.CollectionForm__input}
                            value={formData.email}
                            disabled={true}
                            autoComplete="off"
                        />
                    </div>

                    <div className={styles.CollectionForm__checkboxGroup}>
                        <label className={styles.CollectionForm__checkboxLabel}>
                            <input
                                type="checkbox"
                                name="all"
                                checked={formData.agreements.all}
                                disabled={true}
                            />
                            <span>약관에 모두 동의합니다.</span>
                        </label>
                        <label className={styles.CollectionForm__checkboxLabel}>
                            <input
                                type="checkbox"
                                name="checkPrivacyPolicy"
                                checked={formData.agreements.checkPrivacyPolicy}
                                disabled={true}
                            />
                            <div>상담을 위한{` `}
                                <span 
                                    className={styles.CollectionForm__link}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open('https://waddlelab.notion.site/1741f2d71bda80b49b97c0f082874a0d?pvs=4', '_blank', 'noreferrer,noopener');
                                    }}
                                >개인정보 수집과 이용</span>{` `}동의 (필수)
                            </div>
                        </label>
                        <label className={styles.CollectionForm__checkboxLabel}>
                            <input
                                type="checkbox"
                                name="checkMarketing"
                                checked={formData.agreements.checkMarketing}
                                disabled={true}
                            />
                            <div>혜택 안내를 위한 {` `}
                                <span 
                                    className={styles.CollectionForm__link}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open('https://waddlelab.notion.site/1741f2d71bda805183a3e7066b2f2268?pvs=4', '_blank', 'noreferrer,noopener');
                                    }}
                                >마케팅 활용</span>{` `}동의 (선택)
                            </div>
                        </label>
                    </div>
                </form>
            </div>
        </div>
    )
}