import { useUserEvent } from '../../services/queries/chatbotQueries';
import listStyles from '../../style/styles/Display.module.css';
import styles from '../../style/styles/ProductView.module.css';
import { redirectToPage } from '../../utils/utils';
import { useUserStore } from '../../zustand/store';

export const CarouselListVertical = (props) => {
    const { products, idx } = props;
    return (
        <div key={idx} className={`${listStyles.ProductListVertical__productList} carousel-card`}>
            {products.map((product, productIdx) => (
                <div key={productIdx} className={listStyles.ProductListVertical__productItem}>
                    <div className={listStyles.ProductListVertical__productImageWrapper}>
                        <div className={listStyles.ProductListVertical__productImageBadge}>
                            <p className='h10 fc-white'>추천</p>
                        </div>
                        <img 
                            className={listStyles.ProductListVertical__productImage} 
                            src={product.imageUrl} 
                            alt={product.name} 
                        />
                    </div>
                    <div className={listStyles.ProductList__productInfoContainer}>
                        <div className={listStyles.ProductList__productHeader}>
                            <p className={listStyles.ProductListVertical__productName}>{product.name}</p>
                        </div>
                        <div className='spacing-4' />
                        <div className={listStyles.ProductList__productDetails}>
                            <p className={listStyles.ProductListVertical__productShortDesc}>{product.shortDesc}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export const CarouselSingle = (props) => {
    const { products, idx } = props;
    const { isMobileDevice, partnerId } = useUserStore();
    const { mutate: postUserEvent } = useUserEvent();

    const isDailyshot = ( partnerId === '6737041bcf517dbd2b8b6458');

    return (
        products.map((product, idx) => {
            return (
                <div className={`${styles.ProductCard} ${!isMobileDevice && styles.ExtraPadding__horizontal} carousel-card`} key={idx}>
                    <img
                        src={product?.imageUrl}
                        alt={product?.name}
                        className={styles.ProductCard__productImage}
                    />
                    <div className='spacing-16' />
                    <div className={styles.ProductCard__productInfo}>
                        <p className={styles.ProductCard__companyName}>{product?.tags}</p>
                        <div className='spacing-4' />
                        <div className={styles.ProductCard__productName}>{product?.name}</div>
                        <div className='spacing-8' />
                        <div className={styles.ProductCard__priceWrapper}>
                            {
                                product?.discount > 0 &&
                                <>
                                    <div className={styles.ProductCard__discountRate}>{product?.discount?.toLocaleString()}%</div>
                                    <div className='spacing-2' />
                                </>
                            }
                            <div className={styles.ProductCard__productPrice}>{product?.price?.toLocaleString()}원</div>
                        </div>
                        <div className='spacing-4' />
                        <div className={styles.ProductCard__reviewWrapper}>
                            {
                                product?.rate > 0 ?
                                    <>
                                        <img src='/img/units/star-gray-300.png' width={14} height={14} alt='star' />
                                        <div className='spacing-2' />
                                        <p className={styles.ProductCard__reviewScore}>{product?.rate.toFixed(1)}</p>
                                        <div className='spacing-4' />
                                        <p className={styles.ProductCard__reviewScore}>리뷰 {product?.reviewNum}</p>
                                    </> :
                                    <>
                                        <img src='/img/units/star-gray-300.png' width={14} height={14} alt='star' />
                                        <div className='spacing-2' />
                                        <p className={styles.ProductCard__reviewScore}>신규</p>
                                    </>
                            }
                        </div>
                        <div className='spacing-8' />
                        <div 
                            className={styles.ProductCard__detailButton}
                            onClick={() => {
                                redirectToPage(
                                    product?.productUrl, 
                                    false, 
                                    (isDailyshot && isMobileDevice) ? false : true
                                );
                                postUserEvent({
                                    eventCategory: 'CarouselClicked',
                                });
                            }}
                        >
                            <p 
                                className={styles.ProductCard__detailButtonText}
                            >자세히 보기</p>
                        </div>
                    </div>
                </div>
            )
        })
    )
}