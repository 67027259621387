import { useParams, useSearchParams } from "react-router-dom";
import ChatFieldBaseTest from "../Common/ChatFieldBaseTest";
import { getCustomProperty } from "../../utils/utils";


//COMM: Main Component that contains messages, textfield, etc.
const ChatTest = () => {
    const partnerType = useParams().partnerType;
    const partnerId = useParams().partnerId;
    const chatbotId = useParams().chatBotId;
    const chatUserId = useParams().chatUserId;

    const queryString = window.location.search;
    const [searchParams, setSearchParams] = new useSearchParams(queryString);
    const type = searchParams.get('t');
    const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isMobile = searchParams.get('ch') || isMobileDevice;
    
    const customProperty = (partnerType !== 'dlst' ? getCustomProperty('anchovy') : getCustomProperty('dlst'));

    const reqParams = {
        itemId: 'general',
        chatUserId: chatUserId,
        type: type,
        isMobile: isMobile,
        floatingComment: null,
        partnerId: partnerId, // randomId, mallId
        partnerType: partnerType, // clientId
        chatbotId: chatbotId, // default 1
    }

    return (
        <ChatFieldBaseTest
            // chatURL={process.env.REACT_APP_DEMO_CHAT_URL}
            // resetURL={process.env.REACT_APP_DEMO_RENEW_URL}
            // apiKey={process.env.REACT_APP_X_API_KEY_DEMO}
            customProperty={customProperty}
            reqParams={reqParams}
        />
    );
};

export default ChatTest;