import React, { useEffect, useRef, useState } from 'react';
import styles from '../../style/styles/ProductView.module.css';
import { useChatStateStore, useProductsStore, useUserStore } from '../../zustand/store';
import { useExampleQuery } from '../../services/queries/chatbotQueries';
import PaginationDots from './PaginationDots';
import { ProductShrinkView } from './ProductShrinkView';
import { CarouselListVertical, CarouselSingle } from './CarouselList';
import { findObjectPosition } from '../../utils/utils';

export const CarouselContainer = (props) => {
    const { isDisplayOpen, type } = props;
    const { products, structuredProducts } = useProductsStore();
    const { isMobileDevice } = useUserStore();
    const { currentItemId, currentShrinkCardIndex, currentListCardIndex, setCurrentItemId, setCurrentShrinkCardIndex, setCurrentListCardIndex } = useChatStateStore();
    const { refetch: refetchExample } = useExampleQuery();

    const carouselRef = useRef(null);
    const containerRef = useRef(null);
    const [activeDotIndex, setActiveDotIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(currentShrinkCardIndex);
    const [startX, setStartX] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [cardWidth, setCardWidth] = useState(window.innerWidth);

    useEffect(() => {
        const carouselWrapElement = document.getElementById('carousel-wrap');
        const index = isDisplayOpen && type === 'list' ? currentListCardIndex : currentShrinkCardIndex; 
        const carouselCardWidth = 
            isDisplayOpen ? 
            (
                type === 'list' ?
                cardWidth * structuredProducts.length :
                cardWidth * products.length
            ) : 
            cardWidth * products.length;
        setActiveDotIndex(index);
        setCurrentIndex(index);
        updateCardIndex(index);

        carouselWrapElement.scrollTo({
            left: index * cardWidth,
            behavior: 'smooth'
        });

        if (isDisplayOpen) {
            document.documentElement.style.setProperty('--carousel-wrap-width', carouselCardWidth + 'px');
        } else {
            document.documentElement.style.setProperty('--carousel-wrap-width-shrink', carouselCardWidth + 'px');
        }
    }, [isDisplayOpen]);

    useEffect(() => {
        window.addEventListener('resize', setCardWidth(window.innerWidth));

        return () => {
            window.removeEventListener('resize', setCardWidth(window.innerWidth));
        };
    }, []);

    useEffect(() => {
        const newItemIds = (
            isDisplayOpen && type === 'list' ?
            structuredProducts[currentListCardIndex].map(product => String(product.itemId)) :
            [String(products[currentShrinkCardIndex].itemId)]
        )
        setCurrentItemId(newItemIds);
    }, [currentListCardIndex, currentShrinkCardIndex]);

    const updateCardIndex = (index) => {
        if (isDisplayOpen && type === 'list') {
            setCurrentListCardIndex(index);
            const foundIndex = findObjectPosition([products], structuredProducts[index][0].itemId);
            setCurrentShrinkCardIndex(foundIndex[1]);
        } else {
            setCurrentShrinkCardIndex(index);
            const foundIndex = findObjectPosition(structuredProducts, products[index].itemId);
            setCurrentListCardIndex(foundIndex[0]);
        }
        setActiveDotIndex(index);
    }

    const getEventX = (e) => {
        if (e?.type?.includes('mouse')) {
            return e.pageX;
        }
        return e?.type === 'touchend' ? e.changedTouches[0].pageX : e.touches[0].pageX;
    };

    const startDragging = (e) => {
        if (!isMobileDevice) return;
        setIsDragging(true);
        setStartX(getEventX(e));
    };

    const drag = (e) => {
        if (!isMobileDevice) return;
        if (!isDragging) return;

        // e.preventDefault();
        const currentX = getEventX(e);
        const diff = currentX - startX;
        const len = isDisplayOpen ? structuredProducts.length : products.length;

        // Limit dragging at the edges
        if (
            (currentIndex === 0 && diff > 0) ||
            (currentIndex === len - 1 && diff < 0)
        ) {
            return;
        }
    };

    const endDragging = (e) => {
        // for only mobile device
        if (!isMobileDevice) return;
        if (!isDragging) return;
        const carouselWrapElement = document.getElementById('carousel-wrap');
        
        setIsDragging(false);
        
        const currentX = getEventX(e);
        const diff = currentX - startX;
        const threshold = cardWidth * 0.1;
        const cardNumber = isDisplayOpen && type === 'list' ? structuredProducts.length : products.length;
        
        if (Math.abs(diff) > threshold) {
            if (diff > 0 && currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
                updateCardIndex(currentIndex - 1);
                carouselWrapElement.scrollTo({
                    left: (currentIndex - 1) * cardWidth,
                    behavior: 'smooth'
                });
            } else if (diff < 0 && currentIndex < cardNumber - 1) {
                setCurrentIndex(currentIndex + 1);
                updateCardIndex(currentIndex + 1);
                carouselWrapElement.scrollTo({
                    left: (currentIndex + 1) * cardWidth,
                    behavior: 'smooth'
                });
            }

        } else {
            carouselWrapElement.scrollTo({
                left: currentIndex * cardWidth,
                behavior: 'smooth'
            });
        }
    };

    const handleCarouselPrev = () => {
        const carouselWrapElement = document.getElementById('carousel-wrap');
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            updateCardIndex(currentIndex - 1);
            carouselWrapElement.scrollTo({
                left: (currentIndex - 1) * cardWidth,
                behavior: 'smooth'
            });
        }
    };

    const handleCarouselNext = () => {
        const carouselWrapElement = document.getElementById('carousel-wrap');
        if (isDisplayOpen && type === 'list') {
            if (currentIndex < structuredProducts.length - 1) {
                setCurrentIndex(currentIndex + 1);
                updateCardIndex(currentIndex + 1);
                carouselWrapElement.scrollTo({
                    left: (currentIndex + 1) * cardWidth,
                    behavior: 'smooth'
                });
            }
        } else {
            if (currentIndex < products.length - 1) {
                setCurrentIndex(currentIndex + 1);
                updateCardIndex(currentIndex + 1);
                carouselWrapElement.scrollTo({
                    left: (currentIndex + 1) * cardWidth,
                    behavior: 'smooth'
                });
            }
        }
    };

    useEffect(() => {
        if (currentItemId.length > 0) {
            refetchExample();
        }
    }, [currentItemId]);

    return (
        <div
            className={styles.CarouselContainer__container}
            ref={containerRef}
            onContextMenu={(e) => e.preventDefault()}
        >
            <div 
                id='carousel-wrap' 
                className={isDisplayOpen ? styles.CarouselContainer__scrollBox : styles.CarouselContainer__scrollBox__shrink}
                style={{ overflowX: !isMobileDevice && 'hidden'}}
            >
                <div
                    
                    className={isDisplayOpen ? styles.CarouselContainer__wrap : styles.CarouselContainer__wrap__shrink}
                    ref={carouselRef}
                    onMouseDown={startDragging}
                    onTouchStart={startDragging}
                    onMouseMove={drag}
                    onTouchMove={drag}
                    onMouseUp={endDragging}
                    onTouchEnd={endDragging}
                    onMouseLeave={() => isDragging && endDragging()}
                >
                    {
                        isDisplayOpen ?
                        (
                            type === 'list' ?
                            structuredProducts.map((products, idx) => (
                                <div style={{width: '100%', padding: !isMobileDevice ? '0 52px' : '0 28px'}}>
                                    <CarouselListVertical products={products} idx={idx} /> 
                                </div>
                            )) :
                            <CarouselSingle products={products}/>
                        ):
                        <ProductShrinkView singleProductList={products}/>
                    }
                </div>
                {
                    !isMobileDevice && 
                    (
                        (isDisplayOpen && type === 'list') ? structuredProducts.length > 1 : products.length > 1) &&
                    <>
                        <img 
                            src={`${currentIndex === 0 ? '/img/units/carousel-prev-inactive.png' : '/img/units/carousel-prev-active.png'}`} 
                            width={32} height={32} 
                            style={{position: 'absolute', top: isDisplayOpen ? '35%' : '10%', left: '16px', cursor: currentIndex === 0 ? 'not-allowed' : 'pointer'}} 
                            onClick={handleCarouselPrev}
                        />
                        <img 
                            src={`${currentIndex === (isDisplayOpen && type === 'list' ? structuredProducts.length - 1 : products.length - 1) ? '/img/units/carousel-next-inactive.png' : '/img/units/carousel-next-active.png'}`} 
                            width={32} height={32} 
                            style={{position: 'absolute', top: isDisplayOpen ? '35%' : '10%', right: '16px', cursor: currentIndex === (isDisplayOpen && type === 'list' ? structuredProducts.length - 1 : products.length - 1) ? 'not-allowed' : 'pointer'}} 
                            onClick={handleCarouselNext}
                        />
                    </>
                }
            </div>
            <div className={styles.ProductCard__paginationContainer}>
                {
                    (
                        (
                            isDisplayOpen && structuredProducts.length > 1
                        ) || (
                            !isDisplayOpen && products.length > 1
                        ) || (
                            type !== 'list' && products.length > 1
                        )
                    ) &&
                    <PaginationDots 
                        totalDots={isDisplayOpen && type === 'list' ? structuredProducts.length : products.length} 
                        activeDotIndex={activeDotIndex} 
                        maxDots={6} 
                    /> 
                }
            </div>
        </div>
    );
};
