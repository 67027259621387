import axios from 'axios';

export const mainApi = axios.create({
    baseURL: process.env.REACT_APP_MAIN_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const chatApi = axios.create({
    baseURL: process.env.REACT_APP_CHAT_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const anchovyApi = axios.create({
    baseURL: 'https://nldr4qrozd.execute-api.ap-northeast-2.amazonaws.com/dev',
    headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'YDQl88jEiY9yXd9St5wOJGKM7owwpRL9GrxngDkc',
    },
});