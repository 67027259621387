const PaginationDots = ({ totalDots = 0, activeDotIndex = 0, maxDots = 6 }) => {
    if (totalDots === 0) return null;
    
    const visibleDots = Math.min(totalDots, maxDots);
    const isActiveDot = Math.min(activeDotIndex, visibleDots - 1);
  
    return (
      <div 
        style={{
          display: 'flex',
          gap: '6px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {Array.from({ length: visibleDots }).map((_, index) => (
          <div
            key={index}
            style={{
              width: '6px',
              height: '6px',
              borderRadius: '50%',
              backgroundColor: index === isActiveDot ? '#999' : '#e1e1e1',
              transition: 'background-color 0.3s ease',
            }}
          />
        ))}
      </div>
    );
  };
  
  export default PaginationDots;