import React, { useEffect, useRef, useState } from 'react';
import styles from '../../style/styles/ProductView.module.css';
import listStyles from '../../style/styles/Display.module.css';
import { useChatStateStore, useProductsStore, useUserStore } from '../../zustand/store';
import { useExampleQuery } from '../../services/queries/chatbotQueries';
import { groupProductsUsingReduce, redirectToPage } from '../../utils/utils';
import PaginationDots from './PaginationDots';

export const ProductListHorizontalView = (props) => {
    const { isDisplayOpen } = props;
    const { products } = useProductsStore();
    const { isMobileDevice } = useUserStore();
    const { currentItemId, currentListCardIndex, setCurrentItemId, setCurrentListCardIndex } = useChatStateStore();
    const { refetch: refetchExample } = useExampleQuery();

    const carouselRef = useRef(null);
    const containerRef = useRef(null);
    const [activeDotIndex, setActiveDotIndex] = useState(0);
    const [sliceIndex, setSliceIndex] = useState(3);
    const [currentIndex, setCurrentIndex] = useState(currentListCardIndex);
    const [startX, setStartX] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [cardWidth, setCardWidth] = useState(0);

    useEffect(() => {
        if (isDisplayOpen) {
            setSliceIndex(3);
            setActiveDotIndex(Math.floor(activeDotIndex / 3));
        } else {
            setSliceIndex(1);
        }
    }, [isDisplayOpen]);

    const structuredProducts = groupProductsUsingReduce(products, sliceIndex);

    // const displayedProducts = products.slice(
    //     activeDotIndex * sliceIndex, 
    //     (activeDotIndex * sliceIndex) + sliceIndex
    // );

    useEffect(() => {
        const updateCardWidth = () => {
            if (containerRef.current) {
                setCardWidth(containerRef.current.offsetWidth);
            }
        };

        updateCardWidth();
        window.addEventListener('resize', updateCardWidth);

        return () => {
            window.removeEventListener('resize', updateCardWidth);
        };
    }, []);

    useEffect(() => {
        const newItemIds = structuredProducts[currentListCardIndex].map(product => product.itemId);
        setCurrentItemId(newItemIds);
    }, [currentListCardIndex])

    const getEventX = (e) => {
        if (e.type.includes('mouse')) {
            return e.pageX;
        }
        return e.type === 'touchend' ? e.changedTouches[0].pageX : e.touches[0].pageX;
    };

    const startDragging = (e) => {
        setIsDragging(true);
        setStartX(getEventX(e));
        if (carouselRef.current) {
            carouselRef.current.style.transition = 'none';
        }
    };

    const drag = (e) => {
        if (!isDragging) return;

        // e.preventDefault();
        const currentX = getEventX(e);
        const diff = currentX - startX;
        const newPosition = -currentIndex * cardWidth + diff;

        // Limit dragging at the edges
        if (
            (currentIndex === 0 && diff > 0) ||
            (currentIndex === Math.ceil(products.length / sliceIndex) - 1 && diff < 0)
        )

        setCarouselPosition(newPosition);
    };

    const endDragging = (e) => {
        if (!isDragging) return;

        setIsDragging(false);
        if (carouselRef.current) {
            carouselRef.current.style.transition = 'transform 0.3s ease-out';
        }

        const currentX = getEventX(e);
        const diff = currentX - startX;
        const threshold = cardWidth * 0.3;

        if (Math.abs(diff) > threshold) {
            if (diff > 0 && currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
            } else if (diff < 0 && currentIndex < Math.ceil(products.length / sliceIndex) - 1) {
                setCurrentIndex(currentIndex + 1);
            }
        }

        updateCarouselPosition();
    };

    const setCarouselPosition = (position) => {
        if (carouselRef.current) {
            carouselRef.current.style.transform = `translateX(${position}px)`;
        }
    };

    const updateCarouselPosition = () => {
        const position = -currentIndex * cardWidth;
        setActiveDotIndex(currentIndex);
        setCarouselPosition(position);
    };

    const handleCarouselPrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleCarouselNext = () => {
        if (currentIndex < Math.ceil(products.length / sliceIndex) - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    useEffect(() => {
        updateCarouselPosition();
        // setCurrentItemId(products[currentIndex].itemId);
        setCurrentListCardIndex(currentIndex);
    }, [currentIndex, cardWidth]);

    useEffect(() => {
        refetchExample();
    }, [currentItemId]);

    return (
        <div
            className={styles.carouselContainer}
            ref={containerRef}
            onContextMenu={(e) => e.preventDefault()}
        >
            <div
                className={listStyles.ProductList__carousel}
                ref={carouselRef}
                onMouseDown={startDragging}
                onTouchStart={startDragging}
                onMouseMove={drag}
                onTouchMove={drag}
                onMouseUp={endDragging}
                onTouchEnd={endDragging}
                onMouseLeave={() => isDragging && endDragging()}
                style={{ 
                    width: cardWidth * Math.ceil(products.length / sliceIndex),
                    height: isDisplayOpen ? `${products.length > 3 ? 142 : products.length * 42}px` : '42px', 
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                {
                    structuredProducts.map((products, idx) => (
                        <div key={idx} style={{width: '100%'}}>
                            {products.map((product, productIdx) => (
                                <div key={productIdx} className={listStyles.ProductList__productItem}>
                                    {
                                        !isMobileDevice &&
                                        <div className='spacing-40' />
                                    }
                                    <img 
                                        className={listStyles.ProductList__productImage} 
                                        src={product.imageUrl} 
                                        alt={product.name} 
                                    />
                                    <div className='spacing-12' />
                                    <div className={listStyles.ProductList__productInfoContainer}>
                                        <div className={listStyles.ProductList__productHeader}>
                                            {
                                                product.tags.length !== 0 &&
                                                <>
                                                    <p className={listStyles.ProductList__companyName}>{product.tags}</p>
                                                    <div className='spacing-4' />
                                                </>
                                            }
                                            <p className={listStyles.ProductList__productName}>{product.name}</p>
                                        </div>
                                        <div className='spacing-4' />
                                        <div className={listStyles.ProductList__productDetails}>
                                            {
                                                product.discount > 0 &&
                                                <>
                                                    <p className={listStyles.ProductList__discountRate}>{product.discount}%</p>
                                                    <div className='spacing-2' />
                                                </>
                                            }
                                            <p className={listStyles.ProductList__price}>{product.price.toLocaleString()}원</p>
                                            <div className='spacing-8' />
                                            {
                                                product.rate > 0 ?
                                                <>
                                                    <img src='/img/units/star-gray-300.png' width={14} height={14} alt='star' />
                                                    <div className='spacing-2' />
                                                    <p className={listStyles.ProductList__reviewScore}>{product.rate.toFixed(1)}</p>
                                                    <div className='spacing-4' />
                                                    <p className={listStyles.ProductList__reviewScore}>리뷰 {product.reviewNum}</p>
                                                </> :
                                                <>
                                                    <img src='/img/units/star-gray-300.png' width={14} height={14} alt='star' />
                                                    <div className='spacing-2' />
                                                    <p className={listStyles.ProductList__reviewScore}>신규</p>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    {
                                        !isMobileDevice &&
                                        <div className='spacing-40' />
                                    }
                                </div>
                            ))}
                        </div>
                    ))
                }
            </div>
            {
                !isMobileDevice && products.length > 3 &&
                <>
                    <img 
                        src={`${currentIndex === 0 ? '/img/units/carousel-prev-inactive.png' : '/img/units/carousel-prev-active.png'}`} 
                        width={32} height={32} 
                        style={{position: 'absolute', top: '40%', left: '16px', cursor: currentIndex === 0 ? 'not-allowed' : 'pointer'}} 
                        onClick={handleCarouselPrev}
                    />
                    <img 
                        src={`${currentIndex === Math.ceil(products.length / sliceIndex) - 1 ? '/img/units/carousel-next-inactive.png' : '/img/units/carousel-next-active.png'}`} 
                        width={32} height={32} 
                        style={{position: 'absolute', top: '40%', right: '16px', cursor: currentIndex === Math.ceil(products.length / sliceIndex) - 1 ? 'not-allowed' : 'pointer'}} 
                        onClick={handleCarouselNext}
                    />
                </>
            }
            <div className={styles.ProductCard__paginationContainer}>
                {products.length > 3 && (
                    <PaginationDots 
                        totalDots={Math.ceil(products.length / sliceIndex)} 
                        activeDotIndex={activeDotIndex} 
                        maxDots={6} 
                    />
                )}
            </div>
        </div>
    );
};

export const ProductListVerticalView = (props) => {
    const { isDisplayOpen } = props;
    const { products } = useProductsStore();
    const { isMobileDevice } = useUserStore();
    const { currentItemId, currentCarouselIndex, setCurrentItemId, setCurrentCarouselIndex } = useChatStateStore();
    const { refetch: refetchExample } = useExampleQuery();

    const carouselRef = useRef(null);
    const containerRef = useRef(null);
    const [activeDotIndex, setActiveDotIndex] = useState(0);
    const [sliceIndex, setSliceIndex] = useState(3);
    const [currentIndex, setCurrentIndex] = useState(currentCarouselIndex);
    const [startX, setStartX] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [cardWidth, setCardWidth] = useState(0);

    useEffect(() => {
        if (isDisplayOpen) {
            setSliceIndex(3);
            setActiveDotIndex(Math.floor(activeDotIndex / 3));
        } else {
            setSliceIndex(1);
        }
    }, [isDisplayOpen]);

    const structuredProducts = groupProductsUsingReduce(products, sliceIndex);

    // const displayedProducts = products.slice(
    //     activeDotIndex * sliceIndex, 
    //     (activeDotIndex * sliceIndex) + sliceIndex
    // );

    useEffect(() => {
        const updateCardWidth = () => {
            if (containerRef.current) {
                setCardWidth(containerRef.current.offsetWidth);
            }
        };

        updateCardWidth();
        window.addEventListener('resize', updateCardWidth);

        return () => {
            window.removeEventListener('resize', updateCardWidth);
        };
    }, []);

    useEffect(() => {
        const newItemIds = structuredProducts[currentCarouselIndex].map(product => String(product.itemId));
        setCurrentItemId(newItemIds);
    }, [currentCarouselIndex])

    const getEventX = (e) => {
        if (e.type.includes('mouse')) {
            return e.pageX;
        }
        return e.type === 'touchend' ? e.changedTouches[0].pageX : e.touches[0].pageX;
    };

    const startDragging = (e) => {
        setIsDragging(true);
        setStartX(getEventX(e));
        if (carouselRef.current) {
            carouselRef.current.style.transition = 'none';
        }
    };

    const drag = (e) => {
        if (!isDragging) return;

        // e.preventDefault();
        const currentX = getEventX(e);
        const diff = currentX - startX;
        const newPosition = -currentIndex * cardWidth + diff;

        // Limit dragging at the edges
        if (
            (currentIndex === 0 && diff > 0) ||
            (currentIndex === Math.ceil(products.length / sliceIndex) - 1 && diff < 0)
        )

        setCarouselPosition(newPosition);
    };

    const endDragging = (e) => {
        if (!isDragging) return;

        setIsDragging(false);
        if (carouselRef.current) {
            carouselRef.current.style.transition = 'transform 0.3s ease-out';
        }

        const currentX = getEventX(e);
        const diff = currentX - startX;
        const threshold = cardWidth * 0.3;

        if (Math.abs(diff) > threshold) {
            if (diff > 0 && currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
            } else if (diff < 0 && currentIndex < Math.ceil(products.length / sliceIndex) - 1) {
                setCurrentIndex(currentIndex + 1);
            }
        }

        updateCarouselPosition();
    };

    const setCarouselPosition = (position) => {
        if (carouselRef.current) {
            // carouselRef.current.style.transform = `translateX(${position}px)`;
        }
    };

    const updateCarouselPosition = () => {
        const position = -currentIndex * cardWidth;
        setActiveDotIndex(currentIndex);
        setCarouselPosition(position);
    };

    const handleCarouselPrev = () => {
        if (isDisplayOpen) {
            if (currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
                const carouselCardsElement = document.getElementsByClassName('carousel-card');
                for (let i = 0; i < carouselCardsElement.length; i++) {
                    carouselCardsElement[i].style.transform = `translateX(${-(currentIndex - 1) * carouselCardsElement[i].clientWidth}px)`;
                }
            }
        } else {
            if (currentIndex > 0) {
                setCurrentIndex(currentIndex - 1);
                const carouselCardsElement = document.getElementsByClassName('carousel-card');
                for (let i = 0; i < carouselCardsElement.length; i++) {
                    carouselCardsElement[i].style.transform = `translateX(${-(currentIndex - 1) * carouselCardsElement[i].clientWidth}px)`;
                }
            }
        }
    };

    const handleCarouselNext = () => {
        if (isDisplayOpen) {
            if (currentIndex < Math.ceil(products.length / sliceIndex) - 1) {
                setCurrentIndex(currentIndex + 1);
                const carouselCardsElement = document.getElementsByClassName('carousel-card');
                for (let i = 0; i < carouselCardsElement.length; i++) {
                    carouselCardsElement[i].style.transform = `translateX(${-(currentIndex + 1) * carouselCardsElement[i].clientWidth}px)`;
                }
            }
        } else {
            if (currentIndex < products.length - 1) {
                setCurrentIndex(currentIndex + 1);
                const carouselCardsElement = document.getElementsByClassName('carousel-card');
                for (let i = 0; i < carouselCardsElement.length; i++) {
                    carouselCardsElement[i].style.transform = `translateX(${-(currentIndex + 1) * carouselCardsElement[i].clientWidth}px)`;
                }
            }
        }
    };

    useEffect(() => {
        updateCarouselPosition();
        // setCurrentItemId(products[currentIndex].itemId);
        setCurrentCarouselIndex(currentIndex);
    }, [currentIndex, cardWidth]);

    useEffect(() => {
        refetchExample();
    }, [currentItemId]);

    return (
        <div
            className={styles.carouselContainer}
            ref={containerRef}
            onContextMenu={(e) => e.preventDefault()}
        >
            <div
                className={listStyles.ProductList__carousel}
                ref={carouselRef}
                onMouseDown={startDragging}
                onTouchStart={startDragging}
                onMouseMove={drag}
                onTouchMove={drag}
                onMouseUp={endDragging}
                onTouchEnd={endDragging}
                onMouseLeave={() => isDragging && endDragging()}
                style={{ 
                    width: cardWidth * Math.ceil(products.length / sliceIndex),
                    height: isDisplayOpen ? '142px' : '42px', 
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                {
                    structuredProducts.map((products, idx) => (
                        isDisplayOpen ?
                        <div key={idx} className={`${listStyles.ProductListVertical__productList} carousel-card`}>
                            {products.map((product, productIdx) => (
                                <div key={productIdx} className={listStyles.ProductListVertical__productItem}>
                                    <div className={listStyles.ProductListVertical__productImageWrapper}>
                                        <div className={listStyles.ProductListVertical__productImageBadge}>
                                            <p className='h10 fc-white'>추천</p>
                                        </div>
                                        <img 
                                            className={listStyles.ProductListVertical__productImage} 
                                            src={product.imageUrl} 
                                            alt={product.name} 
                                        />
                                    </div>
                                    <div className={listStyles.ProductList__productInfoContainer}>
                                        <div className={listStyles.ProductList__productHeader}>
                                            <p className={listStyles.ProductListVertical__productName}>{product.name}</p>
                                        </div>
                                        <div className='spacing-4' />
                                        <div className={listStyles.ProductList__productDetails}>
                                            <p className={listStyles.ProductListVertical__productShortDesc}>{product.shortDesc}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div> :
                        <div key={idx} className={`${listStyles.ProductListVertical__productList__shrink} carousel-card`}>
                            {products.map((product, productIdx) => (
                                <div key={productIdx} className={listStyles.ProductListVertical__productItem__shrink}>
                                    <img src={product.imageUrl} alt={product.name} width={34} height={34} />
                                    <div className='spacing-12' />
                                    <div>
                                        <p className='h8 fc-gray-800'>{product.name}</p>
                                        <div className='spacing-4' />
                                        <p className='h9 fc-gray-500'>{product.shortDesc}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))
                }
            </div>
            {
                ((isDisplayOpen && products.length > 3) || (!isDisplayOpen && products.length > 1)) &&
                <>
                    <img 
                        src={`${currentIndex === 0 ? '/img/units/carousel-prev-inactive.png' : '/img/units/carousel-prev-active.png'}`} 
                        width={32} height={32} 
                        style={{position: 'absolute', top: isDisplayOpen ? '35%' : '20%', left: '16px', cursor: currentIndex === 0 ? 'not-allowed' : 'pointer'}} 
                        onClick={handleCarouselPrev}
                    />
                    <img 
                        src={`${currentIndex === Math.ceil(products.length / sliceIndex) - 1 ? '/img/units/carousel-next-inactive.png' : '/img/units/carousel-next-active.png'}`} 
                        width={32} height={32} 
                        style={{position: 'absolute', top: isDisplayOpen ? '35%' : '20%', right: '16px', cursor: currentIndex === Math.ceil(products.length / sliceIndex) - 1 ? 'not-allowed' : 'pointer'}} 
                        onClick={handleCarouselNext}
                    />
                </>
            }
            <div className={styles.ProductCard__paginationContainer}>
                {
                    ((isDisplayOpen && products.length > 3) || (!isDisplayOpen && products.length > 1)) &&
                    <PaginationDots 
                        totalDots={isDisplayOpen ? Math.ceil(products.length / sliceIndex) : products.length} 
                        activeDotIndex={activeDotIndex} 
                        maxDots={6} 
                    /> 
                }
            </div>
        </div>
    );
};
