import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import './style/styles/global.css';
import ChatDailyshot from './components/Custom/ChatDailyshot';
import { Provider } from 'react-redux';
import store from './redux/store';

import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './utils/queryClient';

import ChatTest from './components/Custom/ChatTest';
import ChatVimil from './components/Custom/ChatVimil';
import { ModuleTest } from './components/ModuleTest';
import ChatDailyshotSDK from './components/Custom/ChatDailyshotSDK';
import { MockupDLST } from './components/MockupDLST';
import Transaction from './components/Common/Transaction';
import RecommendSection from './components/SDK/RecommendSection';
import ChatFieldBase from './components/Common/ChatFieldBase';

const App = () => {
  const randomId = parseInt(Math.random()*1e9);

  const setVh = () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight}px`)
  };
  window.addEventListener('resize', setVh);
  setVh();

  return (
    <BrowserRouter>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path='/' element={<Navigate to={`/mockup/dlst`} />} />
            <Route path='/dlst' element={<Navigate to={`/dlst/${randomId}`} />} />
            <Route path='/dlst/:chatUserId' element={<ChatDailyshot />} />
            <Route path='/viml' element={<Navigate to={`/viml/${randomId}`} />} />
            <Route path='/viml/:chatUserId' element={<ChatVimil />} />
            <Route path='/dlst/sdk' element={<Navigate to={`/dlst/sdk/${randomId}`} />} />
            <Route path='/dlst/sdk/:chatUserId' element={<ChatDailyshotSDK />} />
            
            <Route path='/chat-ff/:partnerType/:partnerId/:chatBotId/:chatUserId' element={<ChatTest />}/>
            <Route path='/chat/:chatBotId/:chatUserId' element={<ChatFieldBase />}/>
            <Route path='/mockup/dlst' element={<MockupDLST />} />

            <Route path='/chatroute/:partnerType' element={<Transaction />} />

            <Route path='/recs/:chatUserId' element={<RecommendSection />}/>
            <Route path='/moduleTest' element={<ModuleTest />} />
            <Route path='/test' element={<ChatFieldBase />} />
          </Routes>
        </QueryClientProvider>
      </Provider>
    </BrowserRouter>
  )
}

ReactDOM.createRoot(document.getElementById('root')).render(<App />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
