import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

export const useUserStore = create(
    devtools(
        persist(
            (set) => ({
                partnerId: null,
                partnerType: null,
                chatUserId: null,
                isMobileDevice: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
                
                setIsMobileDevice: (isMobileDevice) => set({ isMobileDevice }),
                setPartnerId: (partnerId) => set({ partnerId }),
                setPartnerType: (partnerType) => set({ partnerType }),
                setChatUserId: (chatUserId) => set({ chatUserId }),
            }),
            {
                name: 'user-storage',
                storage: createJSONStorage(() => sessionStorage),
            }
        )
    )
);

export const useChatbotStore = create(
    devtools(
        persist(
            (set) => ({
                chatbot: null,
                setChatbot: (chatbot) => set({ chatbot }),
            }),
            {
                name: 'chatbot-storage',
                storage: createJSONStorage(() => sessionStorage),
            }
        )
    )
);

export const useChatStateStore = create(
    devtools(
        set => ({
            isChatbotPending: false,
            isNewProduct: false,
            currentItemId: [],
            currentShrinkCardIndex: 0,
            currentListCardIndex: 0,
            isPreAnswered: false,
            isFormSubmitted: 'initial', // initial, ready, submitted
            isExceptional: false,
            setIsChatbotPending: (isChatbotPending) => set({ isChatbotPending }),
            setIsNewProduct: (isNewProduct) => set({ isNewProduct }),
            setCurrentItemId: (currentItemId) => set({ currentItemId }),
            setCurrentShrinkCardIndex: (currentShrinkCardIndex) => set({ currentShrinkCardIndex }),
            setCurrentListCardIndex: (currentListCardIndex) => set({ currentListCardIndex }),
            setIsPreAnswered: (isPreAnswered) => set({ isPreAnswered }),
            setIsFormSubmitted: (isFormSubmitted) => set({ isFormSubmitted }),
            setIsExceptional: (isExceptional) => set({ isExceptional }),
        })
    )
);

export const useMessagesStore = create(
    devtools(
        set => ({
            messages: [],
            setMessages: (messages) => set((prev) => {
                const newMessages = Array.isArray(messages) ? messages : [messages];
                // 스켈레톤이면 대체하기
                if (prev.messages.length > 0 && prev.messages[prev.messages.length - 1].isLoading) {
                    return {
                        messages: [...prev.messages.slice(0, -1), ...newMessages]
                    }
                }
                return {
                    messages: [...prev.messages, ...newMessages]
                }
            }),
            clearMessages: () => set({ messages: [] }),
        })
    )
);

export const useProductsStore = create(
    devtools(
        persist(
            (set) => ({
                products: [],
                examples: [],
                followupQuestion: [],
                structuredProducts: [],
                setProducts: (products) => set((prev) => ({
                    products: [...products, ...prev.products]
                })),
                setExamples: (examples) => set({ examples }),
                setFollowupQuestion: (followupQuestion) => set({ followupQuestion }),
                setStructuredProducts: (structuredProducts) => set((prev) => ({
                    structuredProducts: [...structuredProducts, ...prev.structuredProducts]
                })),
                clearProducts: () => set({ products: [] }),
                clearExamples: () => set({ examples: [] }),
                clearFollowupQuestion: () => set({ followupQuestion: [] }),
                clearStructuredProducts: () => set({ structuredProducts: [] }),
            }),
            {
                name: 'products-storage',
                storage: createJSONStorage(() => sessionStorage),
            }
        )
    )
);

export const useFormStore = create(
    devtools(
        persist(
            (set) => ({
                formData: {},
                setFormData: (formData) => set({ formData }),
            }),
        )
    )
);

export const useUtmStore = create(
    devtools(
        persist(
            set => ({
                utmParams: {},
                setUtmParams: (utmParams) => set({ utmParams }),
            }),
            {
                name: 'utm-storage',
                storage: createJSONStorage(() => sessionStorage),
            }
        )
    )
);