
export const GentooGreetingView = (props) => {
    const { isDisplayOpen } = props;
    return (
        isDisplayOpen ?
        <div style={{ width: '100%', height: '182px', textAlign: 'center' }}>
            <img src='/img/units/gentoo-onboarding.gif' width={320} height={142} alt='gentoo-greeting' />
        </div> :
        <div style={{ width: '100%', height: '82px', textAlign: 'center' }}>
            <img src='/img/units/gentoo-onboarding-shrink.gif' width={320} height={42} alt='gentoo-greeting' />
        </div>
    )
}   

export const GentooLoadingView = (props) => {
    const { isDisplayOpen } = props;
    return (
        isDisplayOpen ?
        <div style={{ width: '100%', height: '182px', textAlign: 'center', backgroundColor: '#fff' }}>
            <img src='/img/units/searching-anime.gif' width={320} height={142} alt='gentoo-loading' />
        </div> :
        <div style={{ width: '100%', height: '82px', textAlign: 'center', backgroundColor: '#fff' }}>
            <img src='/img/units/searching-anime-shrink.gif' width={320} height={42} alt='gentoo-loading' />
        </div>
    )
}