import { useEffect, useState } from 'react';
import style from '../../style/styles/Chat.module.css';
import msgStyle from '../../style/styles/Message.module.css';
import compStyle from '../../style/styles/Components.module.css';
import { progressDataObj } from '../../data/progressData';

export const Skeleton = () => {
    const loadingText = [
        '딱 맞는 상품을 찾는 중...(뒤적뒤적)',
        '상품을 꼼꼼히 분석하는 중...',
        '답변을 정성껏 쓰는 중...',
    ]
    const [textIdx, setTextIdx] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTextIdx(prev => {
                if (prev < loadingText.length - 1) {
                    return prev + 1;
                }
                return prev;
            });
        }, 3000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
            <p className='h3' style={{ color: '#999', }}>{loadingText[textIdx]}</p>
            <div className='spacing-12'/>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', alignSelf: 'stretch'}}>
                <div style={{ width: '100%', height: '16px', }}>
                    <div className={style.Chat__skeletonWrap}>
                        <div className={style.Chat__skeleton}/>
                    </div>
                </div>
                <div className='spacing-8'/>
                <div style={{ width: '110px', height: '16px', }}>
                    <div className={style.Chat__skeletonWrap}>
                        <div className={style.Chat__skeleton}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const Progress = (props) => {
    const { isMobileDevice, progressState } = props;
    const isFirst = progressState === 'first';

    const progressText = (
        isFirst ?
        [
            '질문을 분석하고 있어요..',
            '알맞은 상품을 고르고 있어요..',
            '답변을 작성 중이에요..',
        ] :
        [
            '더 자세한 설명을 작성 중이에요..'
        ]
    )

    const [progress, setProgress] = useState(progressText[0]);

    useEffect(() => {
        let intervalId;
        let index = 1;

        intervalId = setInterval(() => {
            setProgress(progressText[index]);
            index++;

            if(index === progressText.length) {
                clearInterval(intervalId);
            }
        }, 2000)

        return () => {
            clearInterval(intervalId);
            // setProgress(progressText[0]);
        };
    }, [])

    return (
        <>
            <p className='h3' style={{ color: '#999', paddingBottom: '12px' }}>{isFirst ? progress : "더 자세한 설명을 작성 중이에요.."}</p>
            <div className={isMobileDevice ? style.Chat__progressWrap__md : style.Chat__progressWrap}>
                <div className={isMobileDevice ? style.Chat__progress__md : style.Chat__progress} />
            </div>
            <div className={compStyle.Spacing__8} />
            <div className={isMobileDevice ? style.Chat__progressWrap__md : style.Chat__progressWrap} style={{ width: '50%' }}>
                <div className={isMobileDevice ? style.Chat__progress__md : style.Chat__progress} />
            </div>
        </>
    )
}

export const ProgressDLST = (props) => {
    const {partnerType} = props;
    const progressData = partnerType === 'dlst' ? progressDataObj.dlst : progressDataObj.default;
    const randomIdx = Math.floor(Math.random() * progressData.length);

    return (
        <div style={{padding: '0 14px'}}>
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <img src='/img/units/loading-anime.png' width={140} height={140} />
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                {
                    progressData[randomIdx].type === 'info' &&
                    <>
                        <img src='/img/units/skeleton-tip.png' width={35} height={18} />
                        <div className={compStyle.Spacing__8} />
                    </>
                }
                <p className='h6' style={{ color: '#999', paddingBottom: '12px', maxWidth: '206px' }}>{progressData[randomIdx].text}</p>
            </div>
        </div>
    )
}