import { useQuery, useMutation } from "@tanstack/react-query";
import { chatApi } from "../api";
import { useChatStateStore, useMessagesStore, useProductsStore, useUserStore, useUtmStore } from "../../zustand/store";
import { groupProductsUsingReduce } from "../../utils/utils";

export const useChatbot = (partnerId) => {
    return useQuery({
        queryKey: ['chatbot', partnerId],
        queryFn: async () => {
            const response = await chatApi.get(process.env.REACT_APP_CHATBOT_ENDPOINT + `/${partnerId}`);
            console.log('response', response);
            return response.data;
        },
    });
};

export const useMessages = () => {
    const { partnerId, chatUserId, isMobileDevice } = useUserStore();
    const { products, setProducts, clearProducts, structuredProducts, setStructuredProducts } = useProductsStore();
    const { setMessages } = useMessagesStore();
    const { currentItemId, setCurrentItemId, setIsChatbotPending, setIsNewProduct } = useChatStateStore();
    const { setIsFormSubmitted } = useChatStateStore();

    return useMutation({
        mutationFn: async (variables) => {
            setIsFormSubmitted('initial');
            const { message, itemId, preAnswers } = variables;
            const response = await chatApi.post(process.env.REACT_APP_CHAT_ENDPOINT + `/${partnerId}`, {
                requestMessage: String(message),
                itemIds: itemId,
                chatUserId: String(chatUserId),
                channelId: isMobileDevice ? 'mobile' : 'web',
                preAnswers: preAnswers,
            });

            if (response.data.text) {
                setMessages([{ message: response.data.text[0], isChatbot: true }])
            };
            if (response.data.product.length > 0) {
                const newProducts = [...response.data.product, ...products];
                const newStructuredProducts = groupProductsUsingReduce(response.data.product, 3);
                clearProducts();
                setProducts(newProducts);
                setStructuredProducts(newStructuredProducts);
                setIsNewProduct(true);
            } else {
                setIsNewProduct(false);
            }
            setIsChatbotPending(false);
            return response.data;
        },
    });
};

// custom hook for sending message
export const useSendMessage = () => {
	const { mutate: sendMessages, isPending, isSuccess } = useMessages();
	const { currentItemId, setIsChatbotPending } = useChatStateStore();
	const { setMessages } = useMessagesStore();
    const { clearExamples, clearFollowupQuestion } = useProductsStore();

	return {
        sendMessage: (messageInput, isChatbot, preAnswers = []) => {
            clearExamples();
            clearFollowupQuestion();
			sendMessages({ 
                message: messageInput, 
                itemId: currentItemId, 
                preAnswers: preAnswers,
            });
			setMessages([{ 
				message: messageInput, 
				isChatbot: isChatbot,
			}]);
		},
		isPending,
		isSuccess,
	};
}

export const useExampleQuery = () => {
    const { partnerId, chatUserId } = useUserStore();
    const { currentItemId } = useChatStateStore();
    const { setExamples, setFollowupQuestion } = useProductsStore();
    const { messages } = useMessagesStore();
    
    return useQuery({
        queryKey: ['example', partnerId, currentItemId],
        queryFn: async () => {
            await new Promise(resolve => {
                setTimeout(() => {
                    resolve();
                }, 3000);
            });
            
            let attempts = 0;
            const maxAttempts = 5;
            const retryDelay = 1000;

            while (attempts < maxAttempts) {
                attempts++;
                const response = await chatApi.get(process.env.REACT_APP_EXAMPLE_ENDPOINT + `/${partnerId}`, 
                    {
                        params: {
                            chatUserId: String(chatUserId),
                            partnerId: String(partnerId),
                            itemIds: currentItemId,
                        }
                    }
                );

                if (response.data.examples && response.data.examples.length > 0 && messages.length > 0) {
                    setExamples(response.data.examples);
                    setFollowupQuestion(response.data.followupQuestion);
                    return response;
                }

                if (response.data.examples.length === 0 && attempts < maxAttempts) {
                    await new Promise(resolve => setTimeout(resolve, retryDelay));
                }
            }

            // Final attempt only if all retries failed
            const finalResponse = await chatApi.get(process.env.REACT_APP_EXAMPLE_ENDPOINT + `/${partnerId}`, 
                {
                    params: {
                        chatUserId: String(chatUserId),
                        partnerId: String(partnerId),
                        itemIds: currentItemId,
                    }
                }
            );
            // setExamples(finalResponse.data.examples || []);
            return finalResponse;
        },
        enabled: false,
    });
};

export const useResetQuery = () => {
    const { chatUserId, partnerType, partnerId } = useUserStore();
    const { clearMessages } = useMessagesStore();
    const { clearProducts, clearExamples, clearStructuredProducts, clearFollowupQuestion } = useProductsStore();
    const { setIsPreAnswered, setIsFormSubmitted, setCurrentItemId } = useChatStateStore();
    return useMutation({
        mutationFn: async () => {
            const response = await chatApi.post(process.env.REACT_APP_RENEW_ENDPOINT + `/${partnerId}`, {
                chatUserId: String(chatUserId),
            });
            clearMessages();
            clearProducts();
            clearStructuredProducts();
            clearExamples();
            clearFollowupQuestion();
            setCurrentItemId([]);
            setIsPreAnswered(false);
            setIsFormSubmitted('initial');
            return response;
        },
    });
}

export const useFormSubmitted = () => {
    const { chatUserId, partnerId } = useUserStore();
    const { currentItemId } = useChatStateStore();
    const { setMessages } = useMessagesStore();
    const { utmParams } = useUtmStore();

    return useMutation({
        mutationFn: async (variables) => {
            const { formData = {} } = variables;
            const response = await chatApi.post(process.env.REACT_APP_FORM_ENDPOINT + `/${partnerId}`, 
                {
                    chatUserId: String(chatUserId),
                    channelId: 'web',
                    itemId: [String(currentItemId)],
                    formData: {
                        name: formData.name,
                        phone: formData.phone,
                        email: formData.email,
                        checkPrivacyPolicy: formData.agreements.checkPrivacyPolicy,
                        checkMarketing: formData.agreements.checkMarketing,
                    },
                },
                {
                    params: {
                        utm_source: utmParams?.source,
                        utm_medium: utmParams?.medium,
                        utm_campaign: utmParams?.campaign,
                        utm_content: utmParams?.content,
                        utm_term: utmParams?.term,
                        transitions_page: utmParams?.transitionPage,
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    },
                },
                
            );
            setMessages([{ message: response.data.text[0], isChatbot: true }])
            return response.text;
        },
    });
}

export const useUserEvent = () => {
    const { chatUserId, partnerId, isMobileDevice } = useUserStore();

    return useMutation({
        mutationFn: async (variables) => {
            const response = await chatApi.post(process.env.REACT_APP_USEREVENT_ENDPOINT + `/${partnerId}`, {
                channelId: String(isMobileDevice ? 'mobile' : 'web'),
                eventCategory: String(variables.eventCategory),
                chatUserId: String(chatUserId),
                products: [],
            });
            return response;
        },
    });
}