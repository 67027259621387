import { GentooGreetingView, GentooLoadingView } from "./GentooView";
import styles from '../style/styles/Display.module.css';
import { useState } from "react";
import ProductSingleView from "./Carousel/ProductSingleView";
import { ProductListHorizontalView, ProductListVerticalView } from "./Carousel/ProductListView";
import { CarouselContainer } from "./Carousel/CarouselContainer";
import { useUserStore } from "../zustand/store";


const Display = (props) => {
    const { mode } = props;
    const { partnerType } = useUserStore();
    const [isDisplayOpen, setIsDisplayOpen] = useState(true);

    const getDisplayContent = () => {
        switch (mode) {
            case 'gentooGreeting':
                return <GentooGreetingView isDisplayOpen={isDisplayOpen} />;
            case 'productSingle':
                return <CarouselContainer isDisplayOpen={isDisplayOpen} type={'single'} />;
                // return <ProductSingleView isDisplayOpen={isDisplayOpen} />;
            case 'productHorizontalList':
                return <ProductListHorizontalView isDisplayOpen={isDisplayOpen} />;
            case 'productVerticalList':
                // return <ProductListVerticalView isDisplayOpen={isDisplayOpen} />;
                return <CarouselContainer isDisplayOpen={isDisplayOpen} type={partnerType === 'fastfive' ? 'list' : 'single'} />;
            case 'gentooLoading':
                return <GentooLoadingView isDisplayOpen={isDisplayOpen} />;
            default:
                return <GentooGreetingView isDisplayOpen={isDisplayOpen} />;
        }
    }

    return (
        <div className={styles.container}>
            {getDisplayContent()}
            <div className={styles.toggleButtonContainer}>
                <img 
                    src={isDisplayOpen ? '/img/display/display-close.png' : '/img/display/display-open.png'} 
                    width={20} 
                    height={20} 
                    onClick={() => setIsDisplayOpen(!isDisplayOpen)} 
                    alt='close'
                    className={styles.toggleButton}
                />
            </div>
        </div>
    )
}

export default Display;