import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5,
            gcTime: 1000 * 60 * 60,
            retry: 1,
            retryDelay: 1000,

            onError: (error) => {
                console.error(error);
            },
        },
        mutations: {
            onError: (error) => {
                console.error(error);
            },
        },
    },
});