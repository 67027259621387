import { useChatbotStore, useChatStateStore, useUserStore } from "../zustand/store";
import MarkDownConverter from "./Markdown";
import styles from '../style/styles/ChatBubble.module.css';
import buttonStyles from '../style/styles/Buttons.module.css';
import { useState } from "react";
import { Skeleton } from "./Chat/Skeleton";

export const ChatBubble = (props) => {
    const { message, loading } = props;
    const { chatbot } = useChatbotStore();
    const imageUrl = chatbot?.profileImg || '/img/units/gentoo-symbol.png';

    return (
        message.isChatbot ?
        <div className={styles.Bubble__wrapper}>
            <img src={imageUrl} width={32} height={32} />
            <div className='spacing-8' />
            <div style={{ width: '100%' }}>
                <p className={styles.Bubble__name}>{chatbot?.name}</p>
                <div className='spacing-4' />
                    { loading ? 
                        <div className={styles.Bubble__chatbotLoadingMessageWrapper}>
                            <Skeleton />
                        </div> : 
                        <div className={styles.Bubble__chatbotMessageWrapper}>
                            <p className={styles.Bubble__chatbotMessage}><MarkDownConverter text={message.message} /></p>
                        </div>
                    }
            </div>
        </div> :
        <div className={styles.Bubble__myWrapper}>
            <div className={styles.Bubble__myMessageWrapper}>
                <p className={styles.Bubble__myMessage}>{message.message}</p>
            </div>
        </div>
    )
}

export const GreetingBubble = () => {
    const { chatbot } = useChatbotStore();
    const imageUrl = chatbot?.profileImg || '/img/units/gentoo-symbol.png';

    return (
        <div className={styles.Bubble__wrapper}>
            <img src={imageUrl} width={32} height={32} />
            <div className='spacing-8' />
            <div>
                <p className={styles.Bubble__name}>{chatbot?.name}</p>
                <div className='spacing-4' />
                <div className={styles.Bubble__chatbotMessageWrapper}>
                    <p className={styles.Bubble__chatbotMessage}>{chatbot?.greetingMessage}</p>
                </div>
            </div>
        </div>
    )
}

export const TailBubble = (props) => {
    const { message } = props;
    const { setIsFormSubmitted } = useChatStateStore();
    const { partnerType } = useUserStore();

    return (
        message?.length > 0 &&
        <>
            <div className={buttonStyles.TailBubble__wrapper}>
                <div className='spacing-32' />
                <div className='spacing-8' />
                <div>
                    <div className={styles.Bubble__chatbotMessageWrapper}>
                        <p className={styles.Bubble__chatbotMessage}>{message}</p>
                    </div>
                </div>
            </div>
            {
                partnerType === 'fastfive' &&
                <div className={buttonStyles.TailBubble__buttonWrapper}>
                    <div className='spacing-32' />
                    <div className='spacing-8' />
                    <button
                        className={buttonStyles.TailBubble__button}
                        onClick={() => setIsFormSubmitted('ready')}
                    >
                        <p className="h4 fc-white">💥 사무실 최저가 견적 받기</p>
                    </button>
                </div>
            }
        </>
    )
}