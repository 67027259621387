import React, { useEffect, useState } from 'react';
import styles from '../style/styles/ChatInput.module.css';
import { useResetQuery } from '../services/queries/chatbotQueries';
import { useChatStateStore, useMessagesStore, useProductsStore } from '../zustand/store';
import { useSendMessage } from '../services/queries/chatbotQueries';

const ChatInput = () => {
    const { examples, clearExamples } = useProductsStore();
    const { isChatbotPending, setIsChatbotPending } = useChatStateStore();
    const { mutate: resetQuery } = useResetQuery();
    const [userInput, setUserInput] = useState('');
    const { sendMessage, isPending, isSuccess } = useSendMessage();
    const { setMessages } = useMessagesStore();
    const [isInputFocused, setIsInputFocused] = useState(false);
    const handleUserInputChange = (e) => {
        setUserInput(e.target.value);
    }

    const handleKeyPress = async (e) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            e.preventDefault();
            if (isPending) return;
            if (userInput === '') return;
            sendMessage(userInput, false);
            setUserInput('');
            setIsChatbotPending(true);
        }
    };

    const handleReset = () => {
        if (isChatbotPending) return;
        resetQuery();
        setUserInput('');
    }

    const sendInputFocusStatus = (state) => {
        const targetWindow = window.parent;
        const inputFocusState = {
            inputFocusState: state,
        }
        targetWindow.postMessage(inputFocusState, "*");
    }

    const handleInputFocus = (e) => {
        setIsInputFocused(true);
        sendInputFocusStatus(true);
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.sendInputFocusState) {
            window.webkit.messageHandlers.sendInputFocusState.postMessage(`input focused`);
        } else {
            console.warn('webkit.messageHandlers is not available');
        }

        // AOS SDK로 메시지 전달
        if (window.Android && window.Android.sendInputFocusState) {
            window.Android.sendInputFocusState('input focused');
        } else {
            console.warn('Android interface is not available');
        }
    }

    const handleInputBlur = () => {
        setIsInputFocused(false);
        sendInputFocusStatus(false);
    }

    // pending 상태일 때 스켈레톤
    useEffect(() => {
        if (isChatbotPending) {
            setMessages([{
                message: 'Loading...',
                isChatbot: true,
                isLoading: true,
            }]);
        }
    }, [isChatbotPending])

    return (
        <div className={styles.chatInput}>
            <div className={styles.chatInputSuggestions}>
                {examples?.map((example, index) => (
                    <div
                        key={index}
                        className={styles.chatInputSuggestionsItem}
                        onClick={(e) => {
                            e.stopPropagation();
                            sendMessage(example, false);
                            setIsChatbotPending(true);
                            clearExamples();
                        }}
                    >
                        <p
                            className='h3'
                            onClick={(e) => {
                                e.stopPropagation();
                                sendMessage(example, false);
                                setIsChatbotPending(true);
                                clearExamples();
                            }}
                        >{example}</p>
                    </div>
                ))}
            </div>
            <div className={styles.chatInputInputContainer}>
                <div className={styles.chatInputInputContainerWrapper}>
                    <img
                        src={`${!isChatbotPending ? '/img/units/input-reset-active.png' : '/img/units/input-reset-inactive.png'}`}
                        // className={`${styles.resetIcon} ${isChatbotPending && 'non-clickable'}`} 
                        className={`${styles.resetIcon} ${isChatbotPending && 'non-clickable'}`}
                        alt="Reset input"
                        onClick={handleReset}
                    />
                    <div className='spacing-8' />
                    <div className={isChatbotPending ? styles.inputWrapper__disabled : styles.inputWrapper}>
                        <input
                            type='text'
                            placeholder={isChatbotPending ? "답변 중에는 입력이 불가능해요" : "무엇이든 물어보세요"}
                            value={userInput}
                            onChange={handleUserInputChange}
                            onKeyUp={handleKeyPress}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                        />
                        <img
                            src='/img/units/send-inactive.png'
                            className={styles.sendIcon}
                            alt="Send message"
                            onClick={
                                () => {
                                    if (userInput === '') return;
                                    sendMessage(userInput, false);
                                    setUserInput('');
                                    setIsChatbotPending(true);
                                }
                            }
                        />
                    </div>
                </div>
            </div>
            <div className={styles.chatInputPoweredBy}>
                <p className='h9'>powered by GENTOO</p>
            </div>
        </div>
    )
}

export default ChatInput;